
import { Vue, Component, Prop } from "vue-property-decorator";
import ItxModal from "@/Commons/Components/ItxModal.vue";
import axios from "axios";
import API from "@/store/API";
import { sortBy } from "lodash";

import { downloadJSON } from "@/Commons/utils/fileUtils";

@Component({
	components: {
		ItxModal,
	},
})
export default class CopyrightModal extends Vue {
	@Prop({ required: true }) readonly show!: boolean;

	activeTab = 0;

	loaded = false;
	licenses = {
		frontend: null as LicenseDict,
		backend: null as LicenseDict,
	};

	get sortedLicenses() {
		return sortBy(Object.entries(Object.assign(this.licenses.frontend, this.licenses.backend)), (r) => r[0]);
	}

	created() {
		Promise.all([axios.get<LicenseDict>(API.licenses.frontend), axios.get<LicenseDict>(API.licenses.server)]).then(([f, s]) => {
			this.loaded = true;
			this.$set(this.licenses, "frontend", f.data);
			this.$set(this.licenses, "backend", s.data);
		});
	}

	licensesUrl = API.licenses.licensesUrl;

	writeToFile() {
		downloadJSON(
			this.sortedLicenses.map(([title, licenseObject]) => ({
				title,
				versionString: licenseObject.versions.join(", "),
				versionList: Array.isArray(licenseObject.versions) ? licenseObject.versions : [licenseObject.versions],
				licenseName: licenseObject.licenses,
				url: licenseObject.repository,
			})),
		);
	}
}

interface LicenseDict {
	[key: string]: LicenseObject;
}

interface LicenseObject {
	versions: string[];
	licenses: string;
	repository?: string;
	path?: string;
	licenseFile?: string;
	self?: boolean;
	[key: string]: any;
}
