export default class CONST {
	static MEDIATYPE_JSON = "application/json" as const;
	static INFO = "info" as const;
	static ERROR = "error" as const;
	static DUPLICATE_TITLE = "duplicate_title" as const;
	static ZOOM_LEVEL = 24 as const;
}

export const DATA_TYPE = {
	STRING: "System.String",
	INT32: "System.Int32",
	INT64: "System.Int64",
	DOUBLE: "System.Double",
	FLOAT: "System.Float",
	DATETIME: "System.DateTime",
	BOOLEAN: "System.Boolean",
} as const;

export const OBJECT_FINDER_QUERY_TYPE = {
	OBJECT: "object",
	ACTIVITY: "activity",
	ZONE: "zone",
} as const;

export const OBJECT_FINDER_FILTER_TYPE = {
	ZONE: "zone",
	ACTIVITY: "activity",
	PRODUCT: "Products",
	MEANSOFTRANSPORT: "Means of transport",
	PRODUCTIONRESOURCE: "Production ressources and tools",
	TRANSPORTVEHICLE: "Transport vehicles",
} as const;

export const OBJECT_FINDER_OBJECT_POSITION_AVAILABILITY = {
	SET: "set",
	UNSET: "unset",
} as const;
