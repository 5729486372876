
import { dateToTimeString } from "@/Commons/utils/timeUtils";
import { GlobalNotificationInternal } from "@/store/modules/errorsModule";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Errors = namespace("Errors");

@Component({})
export default class ErrorSidebar extends Vue {
	@VModel({ default: false, type: Boolean }) showAll!: boolean;
	@Prop({ required: false, default: 5 }) readonly duration!: number;

	@Errors.Getter notifications!: GlobalNotificationInternal[];
	@Errors.Mutation hideNotifications!: (payload: number[]) => void;
	@Errors.Mutation deleteNotification!: (payload: number) => void;
	@Errors.Mutation clearNotifications!: () => void;

	timerId = -1;
	opened = 0;

	get displayNotifications(): GlobalNotificationInternal[] {
		return this.notifications.filter((n) => this.showAll || n.hide != true);
	}

	created(): void {
		this.timerId = window.setInterval(this.onTick, 1000);
	}

	beforeDestroy(): void {
		if (this.timerId != -1) {
			window.clearInterval(this.timerId);
		}
	}

	onOutsideClick(): void {
		if (new Date().getTime() - this.opened < 300) {
			return;
		}
		if (!this.showAll) {
			return;
		}
		this.showAll = false;
	}

	formatDateTime(d: Date): string {
		return dateToTimeString(d, this.$t("LocalDateTimeFull"));
	}

	onDismiss(notification: GlobalNotificationInternal): void {
		if (this.showAll) {
			window.console.error("Deleting Notification:", notification);
			this.deleteNotification(notification.id);
		} else {
			window.console.error("Hiding Notification:", notification);
			this.hideNotifications([notification.id]);
		}
	}

	onTick(): void {
		const currentDate = new Date();
		const deleteNotifications = this.displayNotifications
			.filter((n) => n.autohide && currentDate.getTime() - n.created.getTime() > this.duration * 1000)
			.map((n) => n.id);
		if (deleteNotifications.length > 0) this.hideNotifications(deleteNotifications);
	}

	@Watch("value")
	onDisplayChange(val: boolean): void {
		this.opened = new Date().getTime();
	}
}
