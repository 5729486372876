import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { i18n } from "./i18n";
import FakeOl from "@/MapApp/utils/FakeOl";
import OlAdapter from "@/MapApp/interfaces/fakeOl";
import { IRepresentationController, IOutlinedObjectController, IMapWidgetController } from "@/MapApp/interfaces/commons";
import vueDataComponents from "./plugins/vueDataComponents";

Vue.config.productionTip = false;

Vue.use(vueDataComponents);

declare global {
	interface Window {
		Resources: any;
		Indutrax: {
			MapVisu: {
				RepresentationControllers: { [key: string]: new () => IRepresentationController };
				OutlinedObjectControllers: IOutlinedObjectController[];
				WidgetControllers: { [key: string]: new () => IMapWidgetController };
				OlAdapter: OlAdapter;
			};
		};
	}
}
window.Indutrax.MapVisu.OlAdapter = new FakeOl();

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
