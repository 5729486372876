
import { Vue, Component } from "vue-property-decorator";

@Component
export default class FullscreenIcon extends Vue {
	on = false;

	get icon(): string {
		if (!this.on) {
			return "far fa-expand-wide";
		} else {
			return "far fa-compress-wide";
		}
		// return "fas" + (this.on)? " fa-expand-wide" : "fa-compress-wide";
	}

	toggleFullscreen() {
		this.on = !this.on;
		if (this.on) {
			this.openFullscreen(document.documentElement);
		} else {
			this.closeFullscreen(document.documentElement);
		}
		window.console.error("Toggled Fullscreen", this.on);
	}

	/* View in fullscreen */
	openFullscreen(elem: any) {
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
			// } else if (elem.mozRequestFullScreen) { /* Firefox */
			//     elem.mozRequestFullScreen();
			// } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
			//     elem.webkitRequestFullscreen();
			// } else if (elem.msRequestFullscreen) { /* IE/Edge */
			//     elem.msRequestFullscreen();
		}
	}

	/* Close fullscreen */
	closeFullscreen(elem: any) {
		if (document.exitFullscreen) {
			document.exitFullscreen();
			// } else if (document.mozCancelFullScreen) { /* Firefox */
			//     document.mozCancelFullScreen();
			// } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
			//     document.webkitExitFullscreen();
			// } else if (document.msExitFullscreen) { /* IE/Edge */
			//     document.msExitFullscreen();
		}
	}
}
