export type DirectoryTreeDTO<Extend extends {} = {}> = {
	id: string;
	name: string;
	value?: string; // They are directories if the value is null, else they are files
	children: DirectoryTreeDTO<Extend>[];
	excludedCount: number;
} & Extend;

export function downloadJSON<T extends any>(data: T, filename = "data.json") {
	downloadBlob(new Blob([JSON.stringify(data, null, 2)], { type: "application/json" }), filename);
}

export function downloadBlob(blob: Blob, filename: string) {
	// Create a URL for the Blob
	const url = URL.createObjectURL(blob);

	// Create an anchor element for download
	const downloadElement = document.createElement("a");
	downloadElement.href = url;
	downloadElement.download = filename;

	// Append the element to the DOM (usually not visible)
	document.body.appendChild(downloadElement);

	// Trigger the download
	downloadElement.click();

	// Clean up
	document.body.removeChild(downloadElement);
	URL.revokeObjectURL(url);
}

/** Recursively populates urls of all files deeply nested in a directory */
export function deepFileUrlsFromDirectory(node: DirectoryTreeDTO): string[] {
	const childrenUrls = (node: DirectoryTreeDTO) => node.children.map((n) => n.value).filter((url) => url);
	return node.children.reduce<string[]>((urls, node) => {
		return urls.concat(!node.value ? deepFileUrlsFromDirectory(node) : childrenUrls(node));
	}, childrenUrls(node));
}
