
import { ModelReload } from "@/Commons/interfaces/FrontendCommunication";
import { AccountDTO } from "@/Commons/interfaces/restdto";
import FrontendCommunication from "@/Commons/utils/FrontendCommunication";
import { dateToTimeString } from "@/Commons/utils/timeUtils";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MonitorTemplate from "./MonitorTemplate.vue";

const Communication = namespace("Communication");
const Account = namespace("Account");

// Grace period betweend messages until we decide that the ModelReload
// probably failed
const GRACE_PERIOD = 10000;

enum State {
	NotActive,
	Active,
	Done,
}

@Component({
	components: {
		MonitorTemplate,
	},
})
export default class ModelReloadMonitor extends Vue {
	@Communication.Getter hub: FrontendCommunication;
	@Account.Getter loaded;

	// Workaround to use enum in template
	State: any = State;

	state: State = State.NotActive;
	timeout: number = -1;
	start = new Date();
	stop = new Date();

	receives: ModelReload = {
		start: () => this.onStart(),
		step: () => this.onStep(),
		stop: () => this.onStop(),
	};

	get show(): boolean {
		return this.state != State.NotActive;
	}

	get timeString(): string {
		return dateToTimeString(this.state == State.Done ? this.stop : this.start, this.$t("LocalDateTimeFull"));
	}

	mounted(): void {
		if (!this.hub?.isSubscribed("ModelReload", this.receives)) {
			this.hub?.subscribe("ModelReload", this.receives);
		}
	}

	beforeDestroy() {
		this.hub?.unsubscribe("ModelReload", this.receives);

		if (this.timeout != -1) {
			window.clearTimeout(this.timeout);
		}
	}

	onStart() {
		this.state = State.Active;
		this.start = new Date();
		window.setTimeout(this.onStop, GRACE_PERIOD);
	}

	onStep() {
		if (this.state == State.NotActive) {
			this.start = new Date();
		}
		this.state = State.Active;
		window.setTimeout(this.onStop, GRACE_PERIOD);
	}

	onStop() {
		this.state = State.Done;
		this.stop = new Date();
	}

	reloadPage() {
		window.location.reload();
	}

	@Watch("hub")
	onHubLoad(hub: FrontendCommunication) {
		if (hub != null) {
			this.hub?.subscribe("ModelReload", this.receives);
		}
	}

	@Watch("loaded")
	onAccountChange(val: AccountDTO) {
		if (this.hub == null) {
			return;
		}
		if (val == null) {
			this.hub.unsubscribe("ModelReload", this.receives);
			return;
		} else if (!this.hub.isSubscribed("ModelReload", this.receives)) {
			this.hub.subscribe("ModelReload", this.receives);
			return;
		}
	}
}
