import { TypeDTO, PositionDTO, TypeState, WorkstationDTO, ContainerContent, MarkerDTO } from "../interfaces/interfaces";
import { ContainerState, Commands } from "../model/states";
import CONST from "@/store/const";

type IsTagAndId = (isTag: boolean, tagId: string) => void;

export function isObjectType(val: string, workstation: WorkstationDTO, types: Array<TypeDTO>): boolean {
	let isObject: boolean = false;
	if (workstation && types) {
		types.forEach((value: TypeDTO) => {
			if (value.Pattern != null) {
				const regex = new RegExp(value.Pattern);
				if (val.match(regex)) {
					isObject = true;
				}
			}
		});
	}
	return isObject;
}

export function isObjectTypeWithScanRegex(
	val: string,
	workstation: WorkstationDTO,
	types: Array<TypeDTO>,
	scanRegex?: string,
): { isObject: boolean; input: string } {
	let isObject: boolean = false;
	isObject = isObjectType(val, workstation, types);
	if (!isObject && scanRegex) {
		const match = getSubstringFromRegex(val, scanRegex);
		if (match?.length !== 1) {
			return { isObject, input: val };
		}
		val = match[0];
		isObject = true;
	}
	return { isObject, input: val };
}

export function isTagType(val: string, workstation: WorkstationDTO, associatedTags?: string[]): { isTag: boolean; inputTag: string } {
	let isTag: boolean = false;
	let inputTag: string;
	const values: string[] = [];

	values.push(val);

	//Create tag ids based on prefix and suffix
	workstation.TagTypePatterns.forEach((tagtypePattern) => {
		if (tagtypePattern.ScanRegex) {
			const match = getSubstringFromRegex(val, tagtypePattern.ScanRegex);
			if (match?.length !== 1) {
				return;
			}
			val = match[0];
			values.push(val);
		}

		if (tagtypePattern.Prefix || tagtypePattern.Suffix) {
			if (tagtypePattern.Prefix) {
				values.push(tagtypePattern.Prefix + val);
			}
			if (tagtypePattern.Suffix) {
				values.push(val + tagtypePattern.Suffix);
			}
			if (tagtypePattern.Prefix && tagtypePattern.Suffix) {
				values.push(tagtypePattern.Prefix + val + tagtypePattern.Suffix);
			}
		}
	});

	const validPatterns = workstation.MultipleTagAssociation
		? workstation.TagTypePatterns?.filter((p) => !associatedTags.includes(p.Type.Type))
		: workstation.TagTypePatterns;
	if (workstation && validPatterns) {
		for (let i = 0; i < values.length; i++) {
			const value = values[i];
			validPatterns.forEach((tagTypeDto: TypeDTO) => {
				if (tagTypeDto.Pattern != null) {
					const regex = new RegExp(tagTypeDto.Pattern);
					if (value.match(regex)) {
						isTag = true;
						inputTag = value;
					}
				}
			});
			if (isTag) break;
		}
	}
	return { isTag, inputTag };
}
function getSubstringFromRegex(val: string, scanRegex: string): string[] {
	let matchedString: string[] = [];
	if (scanRegex) {
		const regex = new RegExp(scanRegex);
		matchedString = val.match(regex);
	}
	return matchedString;
}
export function isPositionType(val: string, workstation: WorkstationDTO): Boolean {
	const index = workstation.Positions?.findIndex((p) => p.Name.toLowerCase() == val.toLowerCase()) ?? -1;
	return index > -1;
}

export function getPositionState(positions: MarkerDTO[], val: string): TypeState {
	const state: TypeState = { ExternalName: "", Id: "", NoOfOccurances: 0, State: ContainerState.grey, Type: "", MatchingTypes: [] };
	positions.forEach((value: MarkerDTO) => {
		if (val.toLowerCase() == value.Name.toLowerCase()) {
			state.Id = value.Name;
			state.NoOfOccurances++;
			state.ExternalName = value.Name;
			state.MatchingTypes.push({ Type: value.Name, ExternalName: value.Name });
		}
	});
	if (state.NoOfOccurances > 1) state.State = ContainerState.orange;
	if (state.NoOfOccurances == 0) state.State = ContainerState.red;
	if (state.NoOfOccurances == 1) state.State = ContainerState.green;
	return state;
}
export function getTypeState(types: TypeDTO[], val: string): TypeState {
	let state: TypeState = { ExternalName: "", Id: "", NoOfOccurances: 0, State: ContainerState.grey, Type: "", MatchingTypes: [] };
	const nullState: TypeState = { ExternalName: "", Id: "", NoOfOccurances: 0, State: ContainerState.grey, Type: "", MatchingTypes: [] };
	types.forEach((value: TypeDTO) => {
		if (value.Pattern == null) {
			nullState.NoOfOccurances++;
			nullState.Id = val;
			nullState.Type = value.Type.Type;
			nullState.ExternalName = value.Type.ExternalName;
			nullState.MatchingTypes.push({ Type: value.Type.Type, ExternalName: value.Type.ExternalName });
		} else {
			const regex = new RegExp(value.Pattern);
			if (val.match(regex)) {
				state.NoOfOccurances++;
				state.Id = val;
				state.Type = value.Type.Type;
				state.ExternalName = value.Type.ExternalName;
				state.MatchingTypes.push({ Type: value.Type.Type, ExternalName: value.Type.ExternalName });
			}
		}
	});
	if (state.NoOfOccurances == 0 && nullState.NoOfOccurances > 0) {
		state = nullState;
	}
	state.Id = val;
	if (state.NoOfOccurances > 1) state.State = ContainerState.orange;
	if (state.NoOfOccurances == 0) state.State = ContainerState.red;
	if (state.NoOfOccurances == 1) state.State = ContainerState.green;
	return state;
}

//updateState
export function getState(state: ContainerState) {
	let returnState = "";
	switch (state) {
		case ContainerState.grey:
			returnState = "noInput";
			break;
		case ContainerState.green:
			returnState = "validInput";
			break;
		case ContainerState.red:
			returnState = "invalidInput";
			break;
		case ContainerState.orange:
			returnState = "ambiguousInput";
			break;
		case ContainerState.lightgreen:
			returnState = "incompleteInput";
			break;
		default:
			throw Error("Type not found");
	}
	return returnState;
}

export function generateKommaSeperatedTypes(types: TypeDTO[]): string {
	const values = [];
	Object.keys(types).forEach((prop) => values.push(types[prop].Type.ExternalName));
	return values.join(",");
}
