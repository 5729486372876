
import { TranslateResult } from "vue-i18n";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ItxModal extends Vue {
	@Prop({ required: true }) show: boolean;
	get _show(): boolean {
		return this.show;
	}
	set _show(value) {
		if (!value) {
			this.$emit("cancel");
		}
	}
	@Prop({ default: "" }) readonly id!: string;

	@Prop({ default: "" }) readonly title!: TranslateResult;
	@Prop({ default: false }) readonly notPersistent!: boolean;

	@Prop({ default: true, type: Boolean }) readonly hasOk!: boolean;
	@Prop({ default: false, type: Boolean }) readonly disabledOk!: boolean;
	@Prop({ default: null }) readonly textOk!: TranslateResult;
	@Prop({ default: "fa-check" }) readonly iconOk!: string;
	@Prop({ default: true, type: Boolean }) readonly hasCancel!: boolean;
	@Prop({ default: false, type: Boolean }) readonly disabledCancel!: boolean;
	@Prop({ default: null }) readonly textCancel!: TranslateResult;
	@Prop({ default: "fa-times" }) readonly iconCancel!: string;
	@Prop({ default: false, type: Boolean }) readonly hasReset!: boolean;
	@Prop({ default: false, type: Boolean }) readonly disabledReset!: boolean;
	@Prop({ default: null }) readonly textReset!: TranslateResult;
	@Prop({ default: "fa-undo" }) readonly iconReset!: string;

	@Prop({ default: false, type: Boolean }) readonly fullscreen!: boolean;
	@Prop({ default: -1 }) readonly width!: number;
	@Prop({ default: false, type: Boolean }) readonly sm!: boolean;
	@Prop({ default: false, type: Boolean }) readonly md!: boolean;
	@Prop({ default: false, type: Boolean }) readonly xl!: boolean;
	@Prop({ default: false, type: Boolean }) readonly xxl!: boolean;

	get _width(): number {
		return this.width > 0 ? this.width : this.xxl ? 1200 : this.xl ? 1000 : this.md ? 600 : this.sm ? 400 : 600;
	}
}
