
import FrontendCommunication from "@/Commons/utils/FrontendCommunication";
import { dateToTimeString } from "@/Commons/utils/timeUtils";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MonitorTemplate from "./MonitorTemplate.vue";

var communication = namespace("Communication");

@Component({
	components: {
		MonitorTemplate,
	},
})
export default class ConnectionMonitor extends Vue {
	@Prop({ default: false }) readonly disabled!: boolean;

	@communication.Getter readonly hub!: FrontendCommunication;
	lastUpdate = new Date();
	currentDate = new Date();
	timeout = -1;
	elapsedTime = 0;
	iteration = 0;

	get connected(): boolean {
		return !this.hub?.error || false;
	}

	get show(): boolean {
		return !this.connected && !this.disabled;
	}

	get connectionFailed(): boolean {
		if (this.hub == null) {
			return false;
		}
		return this.iteration >= this.hub?.reconnectAttempts.length;
	}

	get percentage(): number {
		if (this.hub == null) {
			return 0;
		}
		return Math.min((this.elapsedTime / this.hub?.reconnectAttempts[this.iteration]) * 100, 100);
	}

	mounted() {
		this.timeout = window.setInterval(this.timer, 1000);
	}

	timer() {
		if (this.connected || this.hub == null || this.connectionFailed) {
			return;
		}
		this.elapsedTime += 1000;
		if (this.elapsedTime > this.hub.reconnectAttempts[this.iteration]) {
			this.elapsedTime -= this.hub.reconnectAttempts[this.iteration];
			this.iteration++;
		}
	}

	beforeDestroy() {
		window.clearInterval(this.timeout);
	}

	@Watch("connected")
	onConnectedChange(val: boolean) {
		this.lastUpdate = new Date();
		this.iteration = 0;
		this.elapsedTime = 0;
	}
}
