import { ColorsDTO } from "@/Commons/interfaces/reporting";
import { ReportingConfigurationsDTO } from "@/Commons/interfaces/reportsDTOs";
import Axios from "axios";
import { Module } from "vuex";
import { RootState } from "..";
import API from "../API";

export const fallbackColors: ColorsDTO = ["#183B60", "#68145B", "#93641C", "#748D1B"];

interface ReportsState {
	configurations: ReportingConfigurationsDTO | null;
	updateTimestamp: number;
}

const module: Module<ReportsState, RootState> = {
	namespaced: true,
	state: {
		configurations: null,
		updateTimestamp: 0,
	},
	getters: {
		configurations: (state) => state.configurations,
		allReports: (state) => state.configurations?.Reports ?? [],
		colors: (state) => {
			if (!state.configurations) return fallbackColors;
			if (!Array.isArray(state.configurations.ColorSet)) return fallbackColors;
			if (state.configurations.ColorSet.length < 3) return fallbackColors;
			return state.configurations.ColorSet;
		},
		updateTimestamp: (state) => state.updateTimestamp,
	},
	mutations: {
		setConfigurations: (state, configurations: ReportingConfigurationsDTO) => (state.configurations = configurations),
		setUpdateTimestamp: (state) => (state.updateTimestamp = Date.now()),
	},
	actions: {
		fetchConfiguration: (context) => {
			if (context.state.configurations) return;
			Axios.get<ReportingConfigurationsDTO>(API.reportConfigurations)
				.then(({ data }) => {
					context.commit("setConfigurations", data);
					if (!Array.isArray(data?.ColorSet)) window.console.warn("No color set found");
					if (data?.ColorSet.length < 2) window.console.warn("Color set has less than 2 colors");
				})
				.catch((e) => {
					window.console.error("Error while loading reports configuration", e);
				});
		},
	},
};

export default module;

/**
 * A utility to reference reporting getters, without having to hard code the getter name again for the third time.
 * A better solution? Migrate to pinia, which is type-safe.
 **/
export const getterName = (name: "configurations" | "allReports" | "colors" | "updateTimestamp") => `Reports/${name}` as const;
