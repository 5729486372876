import FrontendCommunication from "@/Commons/utils/FrontendCommunication";
import { Module, StoreOptions } from "vuex";
import { RootState } from "..";

export interface Communications {
	hub: FrontendCommunication | null;
}

const module: Module<Communications, RootState> = {
	namespaced: true,
	state: {
		hub: null,
	},
	getters: {
		hub: (state) => state.hub,
	},
	mutations: {
		start: (state) => {
			if (state.hub == null) {
				state.hub = new FrontendCommunication();
			}
		},
		stop: (state) => {
			if (state.hub != null) {
				state.hub = null;
			}
		},
	},
};

export default module;
