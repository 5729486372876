import OlAdapter from "@/MapApp/interfaces/fakeOl";
import { Style, Fill, Stroke, Text } from "ol/style";
import { Color, asArray } from "ol/color";
import { Options as TextOptions } from "ol/style/Text";
import { Options as StrokeOptions } from "ol/style/Stroke";
import { Options as FillOptions } from "ol/style/Fill";
import { Options as StyleOptions } from "ol/style/Style";
import { Extent } from "ol/extent";
import Map from "ol/Map";
import { Coordinate } from "ol/coordinate";

export default class FakeOl implements OlAdapter {
	Style = (options: StyleOptions): Style => {
		return new Style(options);
	};
	Fill = (options: FillOptions): Fill => {
		return new Fill(options);
	};
	Stroke = (options: StrokeOptions): Stroke => {
		return new Stroke(options);
	};
	Text = (options: TextOptions): Text => {
		// MB: Enable if Nico wants Text to always show
		// if(!("overflow" in options)){
		//     options.overflow = true;
		// }
		return new Text(options);
	};
	ColorAsArray = (hexColor: string): Color => {
		return asArray(hexColor);
	};
}
