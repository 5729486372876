
import { Vue, Component, Watch } from "vue-property-decorator";
import ShiftIconList from "@/Wireframe/Components/ShiftIconList.vue";
import UserMenu from "@/Wireframe/Components/UserMenu.vue";
import InformationMenu from "@/Wireframe/Components/InformationMenu.vue";
import FullscreenIcon from "@/Wireframe/Components/FullscreenIcon.vue";
import SiteSelector from "@/Wireframe/Components/SiteSelector.vue";
import ConnectionMonitor from "@/Wireframe/Components/Monitors/ConnectionMonitor.vue";
import AfterLogoutMonitor from "@/Wireframe/Components/Monitors/AfterLogoutMonitor.vue";
import { namespace } from "vuex-class";
import { routes } from "@/router";
import { RouteConfig } from "vue-router";
import { Modules } from "./store/modules/account";
import Axios, { AxiosResponse } from "axios";
import API from "./store/API";
import ItxModal from "@/Commons/Components/ItxModal.vue";
import ModelReloadMonitor from "./Wireframe/Components/Monitors/ModelReloadMonitor.vue";
import ErrorSidebar from "./Wireframe/Components/ErrorSidebar.vue";
import { GlobalNotification } from "./store/modules/errorsModule";
import { AccountDTO, AccountRoleDTO } from "./Commons/interfaces/restdto";
import NotificationIcon from "./Wireframe/Components/NotificationIcon.vue";
import LoginPage from "./views/LoginPage.vue";
import { isIFrame } from "./Commons/utils/state";

const Reports = namespace("Reports");
const Account = namespace("Account");
const Communications = namespace("Communication");
const Errors = namespace("Errors");

@Component({
	components: {
		ShiftIconList,
		UserMenu,
		InformationMenu,
		FullscreenIcon,
		SiteSelector,
		ItxModal,
		ConnectionMonitor,
		AfterLogoutMonitor,
		ModelReloadMonitor,
		ErrorSidebar,
		NotificationIcon,
		LoginPage,
	},
})
export default class App extends Vue {
	drawer: boolean = true;
	menuOpen: boolean = false;
	menuShow = false;
	modelIsValid = true;
	hideDisconnectedMessage = false;
	showNotificationSidebar = false;

	@Reports.Action fetchConfiguration!: () => Promise<void>;
	@Account.Getter fetched!: boolean;
	@Account.Getter loggedIn!: boolean;
	@Account.Getter roles!: AccountRoleDTO[];
	@Account.Getter claims!: string[];
	@Account.Getter modules: Modules[];
	@Communications.Mutation("start") startHub!: () => void;
	@Communications.Mutation("stop") stopHub!: () => void;
	@Errors.Getter activeNotifications!: GlobalNotification[];

	get showMenu(): boolean {
		return this.menuShow || !this.$vuetify.breakpoint.mobile;
	}

	set showMenu(val: boolean) {
		this.menuShow = val;
	}

	get filteredItems(): RouteConfig[] {
		return routes.filter(
			(s) =>
				(this.isAdmin || // admins can see every page
					this.claims.includes(s.meta.rolePage)) && // user has the right to see the page
				(s.meta.modules == undefined || // page is not restricted by module
					s.meta.modules.some((m: Modules) => this.modules.includes(m))), // Module for page is enabled
		);
	}

	get ActivitiesAndShiftsEnabled() {
		return this.modules?.indexOf(Modules.ActivitiesAndShifts) > -1;
	}

	isIFrame = isIFrame();

	created() {
		document.title = "INDUTRAX AppEnv";

		// Load Colors from theme
		Axios.get(API.theme.colors).then((response) => {
			this.$vuetify.theme.themes.light = Object.assign(this.$vuetify.theme.themes.light, response.data);
		});

		this.checkLogos();
		this.startHub();
		this.fetchConfiguration();
	}

	overwriteBreakpoint: boolean = true;
	get mdAndUp() {
		return this.$vuetify.breakpoint.smAndUp;
	}
	get mini() {
		return this.$vuetify.breakpoint.mdAndDown || this.overwriteBreakpoint;
	}
	set mini(value) {
		this.overwriteBreakpoint = value;
	}
	get breakpoint() {
		return JSON.stringify(this.$vuetify.breakpoint, null, 2);
	}
	menuClick() {
		this.menuOpen = !this.menuOpen;
		return this.menuOpen;
	}

	get logoUrl() {
		return API.theme.logo;
	}
	get logoTextUrl() {
		return API.theme.logoText;
	}

	hasLogo: boolean = false;
	hasLogoText: boolean = false;

	checkLogos() {
		Axios.get(this.logoUrl)
			.then((response) => {
				this.hasLogo = true;
			})
			.catch((error) => {
				this.hasLogo = false;
			});
		Axios.get(this.logoTextUrl)
			.then((response) => {
				this.hasLogoText = true;
			})
			.catch((error) => {
				this.hasLogoText = false;
			});
	}

	isDebugSite(site: any) {
		if (site?.meta?.modules == null) {
			return false;
		}

		return this.modules.includes(Modules.Debug) && site.meta.modules.includes(Modules.Debug);
	}

	get isAdmin(): boolean {
		if (!this.roles) {
			return false;
		}
		return this.roles.filter((s) => s.NormalizedName == "ADMIN").length > 0;
	}

	@Watch("loggedIn")
	onAccountChange(loggedIn: AccountDTO) {
		if (loggedIn) {
			Axios.get(API.modelValid).then((response: AxiosResponse<boolean>) => {
				this.modelIsValid = response.data;
				this.startHub();
			});
		} else {
			this.stopHub();
		}
	}
}
