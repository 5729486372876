export enum UpdateResult {
	ERROR,
	GOOD,
	BAD,
	ALREADY_KNOW_THIS,
	COMPLETE,
}

export enum ContainerState {
	green,
	red,
	grey,
	orange,
	lightgreen,
}

export enum Commands {
	marry,
	gatemarry,
	gate,
	link,
	couple,
	decouple,
	gatecouple,
	divorce,
	scan_obj,
	scan_obj_obj,
	scan_obj_tag,
}

export enum ShopFloorObjectType {
	object,
	driver,
	child,
	parent,
	tag,
	position,
}

export class CONST {
	public static POST = "POST";
	public static GET = "GET";
	public static PUT = "PUT";
	public static DELETE = "DELETE";
	public static MEDIATYPE_JSON = "application/json";
	public static OBJECT = "object";
	public static CHILD = "child";
	public static PARENT = "parent";
	public static DRIVER = "driver";
}

export enum TagValidationType {
	VALID = "VALID",
	NOT_ACTIVE = "NOT_ACTIVE",
	NOT_TRACKED = "NOT_TRACKED",
}
