
import FrontendCommunication from "@/Commons/utils/FrontendCommunication";
import { dateToTimeString } from "@/Commons/utils/timeUtils";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MonitorTemplate from "./MonitorTemplate.vue";

const Communication = namespace("Communication");
const Account = namespace("Account");

@Component({
	components: {
		MonitorTemplate,
	},
})
export default class AfterLogoutMonitor extends Vue {
	@Communication.Getter readonly hub!: FrontendCommunication;
	@Account.Getter readonly loggedIn;
	@Account.Getter readonly loaded;
	@Account.Action fetchAccount;

	lastUpdate = new Date();
	hide = false;
	unsure = false;

	get connected(): boolean {
		return !this.hub?.running == true;
	}

	get show(): boolean {
		return this.loaded && !this.loggedIn && !this.hide;
	}

	set show(val: boolean) {
		this.hide = val;
	}

	get formattedDate(): string {
		return dateToTimeString(this.lastUpdate, this.$t("LocalDate"));
	}

	get formattedTime(): string {
		return dateToTimeString(this.lastUpdate, this.$t("LocalTime"));
	}

	fetchAccountData() {
		this.unsure = true;
		this.fetchAccount()
			.then((val) => {
				this.unsure = false;
			})
			.catch((reason) => {
				// Directly after server-restart sometimes it take a few seconds for requests to go through
				window.setTimeout(this.fetchAccountData, 5000);
			});
	}

	navigateToLogin() {
		window.location.href = "account/logout";
	}

	@Watch("connected")
	onConnectedChange(val: boolean) {
		this.lastUpdate = new Date();
		this.hide = false;
		if (val == true) {
			this.fetchAccountData();
		}
	}
}
