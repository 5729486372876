import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import MapModule from "./modules/map";
import ExtendedSearchModule from "./modules/extendedSearch";
import Account from "./modules/account";
import ShopFloor from "./modules/shopFloor";
import communications from "./modules/communications";
import Reports from "./modules/reports";
import { SiteDTO } from "@/Commons/interfaces/restdto";
import { ItxLang } from "@/Commons/interfaces";
import { errorsModule } from "./modules/errorsModule";

Vue.use(Vuex);

export type RootState = {
	locale: ItxLang;
	site: SiteDTO | null;
	sites: SiteDTO[];
};

const options: StoreOptions<RootState> = {
	state: {
		locale: "en",
		site: null,
		sites: [],
	},
	getters: {
		locale: (state) => state.locale,
		site: (state): SiteDTO => state.site,
		sites: (state): SiteDTO[] => state.sites,
	},
	mutations: {
		setLocale: (state, locale: ItxLang) => (state.locale = locale),
		setSite: (state, site: SiteDTO) => (state.site = site),
		setSites: (state, sites: SiteDTO[]) => (state.sites = sites),
	},
	actions: {},
	modules: {
		Map: MapModule,
		ExtendedSearch: ExtendedSearchModule,
		Account: Account,
		ShopFloor: ShopFloor,
		Communication: communications,
		Reports,
		Errors: errorsModule,
	},
};

export default new Vuex.Store(options);
