import Vue from "vue";
import VueI18n, { LocaleMessages, LocaleMessageObject } from "vue-i18n";
import axios, { AxiosResponse } from "axios";
import messages from "@/locales/en.json";
import vuetify from "@/plugins/vuetify";
import vuex from "@/store/index";

Vue.use(VueI18n);

export const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
	messages: { "en": messages },
});

const loadedLanguages = ["en"]; // our default language that is preloaded

function setI18nLanguage(lang: string) {
	i18n.locale = lang;
	vuetify.preset.lang.current = lang;
	axios.defaults.headers.common["Accept-Language"] = lang;
	document.querySelector("html").setAttribute("lang", lang);
	localStorage["lang"] = lang;
	vuex.commit("setLocale", lang);
	return lang;
}

export function loadLanguageAsync(lang: string) {
	window.console.debug("Loading language", lang);
	// If the same language
	if (i18n.locale === lang) {
		return Promise.resolve(setI18nLanguage(lang));
	}

	// If the language was already loaded
	if (loadedLanguages.includes(lang)) {
		return Promise.resolve(setI18nLanguage(lang));
	}

	// If the language hasn't been loaded yet
	// return axios.get(API.map.translations).then(
	//   (translations: AxiosResponse<LocaleMessageObject>) => {
	//     i18n.setLocaleMessage(lang, translations.data)
	//     window.console.error(lang, i18n.getLocaleMessage(lang))
	//     loadedLanguages.push(lang)
	//     return setI18nLanguage(lang)
	//   }
	// )
	return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`).then((messages) => {
		i18n.setLocaleMessage(lang, messages.default);
		loadedLanguages.push(lang);
		return setI18nLanguage(lang);
	});
}

// For use outside a vue component. To be used in a way it's reactively called.
// For example, functions that get called on every DOM update.
export function getLocaleText(text: string) {
	return i18n.t(text) || text;
}
