import API from "@/store/API";
import { Module } from "vuex";
import { WorkstationDTO, ContainerContent, AssociatedTag } from "@/ShopFloor/interfaces/interfaces";
import Axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import { ContainerState } from "@/ShopFloor/model/states";
import { getState } from "@/ShopFloor/utils/commons";
import { PositionableObjectDTO } from "@/Commons/interfaces/restdto";
import { RootState } from "..";

export type ShopFloorState = {
	workstations: WorkstationDTO[];
	activeWorkstation: WorkstationDTO | null;
	leftContainerStyle: string;
	rightContainerStyle: string;
	resultStyle: string;
	leftContainerContent: string;
	rightContainerContent: string;
	leftContainerTitle: string;
	rightContainerTitle: string;
	leftContainerToolTip: string;
	rightContainerToolTip: string;
	resultContent: string;
	logContent: string[];
	switchValue: boolean;
	isTextBoxDisabled: boolean;
	isRequestSuccessful: boolean;
	associatedTags: AssociatedTag[];
	rightContainerContentForTag: string;
	instanceId: string;
	deviceObject: PositionableObjectDTO | null;
	showObjectEditModal: boolean;
	editPosoId: string | null;
};

const shopFloorModule: Module<ShopFloorState, RootState> = {
	state: {
		workstations: [],
		activeWorkstation: null,
		leftContainerStyle: "noInput",
		rightContainerStyle: "noInput",
		resultStyle: "noInput",
		leftContainerContent: "",
		rightContainerContent: "",
		leftContainerTitle: "",
		rightContainerTitle: "",
		leftContainerToolTip: "",
		rightContainerToolTip: "",
		resultContent: "",
		logContent: [],
		switchValue: true,
		isTextBoxDisabled: false,
		isRequestSuccessful: false,
		associatedTags: [],
		rightContainerContentForTag: "",
		instanceId: "",
		deviceObject: null,
		showObjectEditModal: false,
		editPosoId: null,
	},
	getters: {
		workstations: (state) => state.workstations,
		instanceId: (state) => state.instanceId,
		deviceObject: (state) => state.deviceObject,
		activeWorkstation: (state) => state.activeWorkstation,
		leftContainerStyle: (state) => state.leftContainerStyle,
		rightContainerStyle: (state) => state.rightContainerStyle,
		resultStyle: (state) => state.resultStyle,
		leftContainerContent: (state) => state.leftContainerContent,
		rightContainerContent: (state) => state.rightContainerContent,
		leftContainerTitle: (state) => state.leftContainerTitle,
		rightContainerTitle: (state) => state.rightContainerTitle,
		leftContainerToolTip: (state) => state.leftContainerToolTip,
		rightContainerToolTip: (state) => state.rightContainerToolTip,
		resultContent: (state) => state.resultContent,
		logContent: (state) => state.logContent,
		switchValue: (state) => state.switchValue,
		isTextBoxDisabled: (state) => state.isTextBoxDisabled,
		isRequestSuccessful: (state) => state.isRequestSuccessful,
		associatedTags: (state) => state.associatedTags,
		rightContainerContentForTag: (state) => state.rightContainerContentForTag,
		showObjectEditModal: (state) => state.showObjectEditModal,
		editPosoId: (state) => state.editPosoId,
	},
	mutations: {
		setWorkstations: (state, workstations: WorkstationDTO[]) => (state.workstations = workstations),
		setInstanceId: (state, instanceId: string) => (state.instanceId = instanceId),
		setDeviceObject: (state, deviceObject: PositionableObjectDTO) => (state.deviceObject = deviceObject),
		setActiveWorkstation: (state, workstation: WorkstationDTO) => (state.activeWorkstation = workstation),
		setLeftContainerStyle: (state, newStyle: string) => (state.leftContainerStyle = newStyle),
		setRightContainerStyle: (state, newStyle: string) => (state.rightContainerStyle = newStyle),
		setResultStyle: (state, newStyle: string) => (state.resultStyle = newStyle),
		setLeftContainerContent: (state, content: string) => (state.leftContainerContent = content),
		setRightContainerContent: (state, content: string) => (state.rightContainerContent = content),
		setLeftContainerTitle: (state, title: string) => (state.leftContainerTitle = title),
		setRightContainerTitle: (state, title: string) => (state.rightContainerTitle = title),
		setLeftContainerToolTip: (state, text: string) => (state.leftContainerToolTip = text),
		setRightContainerToolTip: (state, text: string) => (state.rightContainerToolTip = text),
		setResultContent: (state, content: string) => (state.resultContent = content),
		setLogContent: (state, content: string[]) => state.logContent.push(...content),
		resetUI: (state, containerContent: ContainerContent) => {
			state.leftContainerStyle = "noInput";
			state.rightContainerStyle = "noInput";
			state.resultStyle = "noInput";
			state.isTextBoxDisabled = false;
			if (state.activeWorkstation == null) {
				state.resultContent = "";
				state.leftContainerContent = "";
				state.rightContainerContent = "";
			} else {
				state.resultContent = "";
				state.leftContainerContent = containerContent.leftContent;
				state.rightContainerContent = containerContent.rightContent;
			}
		},
		resetRightUI: (state, containerContent: ContainerContent) => {
			state.rightContainerStyle = "noInput";
			state.resultStyle = "noInput";
			state.isTextBoxDisabled = false;
			state.resultContent = "";
			state.rightContainerContent = containerContent.rightContent;
		},
		setSwitchState: (state, activated: boolean) => (state.switchValue = activated),
		setDisableTextBox: (state, flag: boolean) => (state.isTextBoxDisabled = flag),
		setRequestSuccessful: (state, flag: boolean) => (state.isRequestSuccessful = flag),
		setAssociatedTags: (state, associatedTag: AssociatedTag) => state.associatedTags.push(associatedTag),
		clearAssociatedTags: (state) => (state.associatedTags = []),
		setShowObjectEditModal: (state, showObjectEditModal: boolean) => (state.showObjectEditModal = showObjectEditModal),
		setEditPosoId: (state, posoId: string | null) => (state.editPosoId = posoId),
	},
	actions: {
		async getWorkstations({ commit }) {
			await Axios.get(API.workstations())
				.then((response: AxiosResponse) => {
					if (Array.isArray(response.data)) {
						response.data.sort((wa: WorkstationDTO, wb: WorkstationDTO) => {
							if (wa.ExternalName < wb.ExternalName) {
								return -1;
							}
							if (wa.ExternalName > wb.ExternalName) {
								return 1;
							}
							return 0;
						});
						commit("setWorkstations", response.data);
					}
				})
				.catch((error: AxiosError) => {
					window.console.error("Error while loading workstations.", error);
				});
		},
		async findObjectInDatabase({ commit }, { objectId, type, workstation }) {
			const decodedObjectId = encodeURIComponent(objectId);
			return Axios.get(API.verifyObject(decodedObjectId, workstation.Id, type));
		},
		async validateTag({ commit }, { qualifiedTagId, workstationId }) {
			qualifiedTagId = encodeURIComponent(qualifiedTagId);
			return Axios.get(API.validateTag(qualifiedTagId, workstationId));
		},
		async createObject({ commit }, { type, name, workstation }) {
			return Axios.post(API.FetchOrCreateObject(name, type, workstation.Id));
		},
		async shopFloorRequest({ commit }, { method, url, objectPayload, state, successMessage, errorMessage }) {
			await Axios({ method: method, url: url, data: objectPayload } as AxiosRequestConfig)
				.then((response: any) => {
					if (response.data.Success) {
						commit("setResultStyle", getState(state));
						commit("setResultContent", successMessage);
					} else {
						const message = (response.data.State as string) || errorMessage;
						commit("setResultStyle", getState(ContainerState.red));
						commit("setResultContent", message);
					}
					const logs: string[] = response.data.Value && Array.isArray(response.data.Value) ? response.data.Value : [response.data.Value];
					commit("setLogContent", logs);
					commit("setRequestSuccessful", response.data.Success);
				})
				.catch((error) => {
					commit("setResultStyle", getState(state));
					commit("setResultContent", errorMessage);
					commit("setLogContent", error);
					commit("setRequestSuccessful", false);
				});
		},
		setInstanceId({ commit }, instanceId: string) {
			commit("setInstanceId", instanceId);
		},
		setDeviceObject({ commit }, deviceObject: PositionableObjectDTO) {
			commit("setDeviceObject", deviceObject);
		},
		setActiveWorkstation({ commit }, workstation: WorkstationDTO) {
			commit("setActiveWorkstation", workstation);
		},
		setLeftContainerStyle({ commit }, newStyle: string) {
			commit("setLeftContainerStyle", newStyle);
		},
		setRightContainerStyle({ commit }, newStyle: string) {
			commit("setRightContainerStyle", newStyle);
		},
		setLeftContainerContent({ commit }, content: string) {
			commit("setLeftContainerContent", content);
		},
		setResultStyle({ commit }, newStyle: string) {
			commit("setResultStyle", newStyle);
		},
		setRightContainerContent({ commit }, content: string) {
			commit("setRightContainerContent", content);
		},
		setLeftContainerTitle({ commit }, title: string) {
			commit("setLeftContainerTitle", title);
		},
		setRightContainerTitle({ commit }, title: string) {
			commit("setRightContainerTitle", title);
		},
		setLeftContainerToolTip({ commit }, text: string) {
			commit("setLeftContainerToolTip", text);
		},
		setRightContainerToolTip({ commit }, text: string) {
			commit("setRightContainerToolTip", text);
		},
		setResultContent({ commit }, content: string) {
			commit("setResultContent", content);
		},
		setLogContent({ commit }, content: string) {
			commit("setLogContent", content);
		},
		setSwitchState({ commit }, activated: boolean) {
			commit("setSwitchState", activated);
		},
		resetUI({ commit }, containerContent: ContainerContent) {
			commit("resetUI", containerContent);
		},
		resetRightUI({ commit }, containerContent: ContainerContent) {
			commit("resetRightUI", containerContent);
		},
		setDisableTextBox({ commit }, flag: boolean) {
			commit("setDisableTextBox", flag);
		},
		setAssociatedTags({ commit }, associatedTag: AssociatedTag) {
			commit("setAssociatedTags", associatedTag);
		},
		clearAssociatedTags({ commit }) {
			commit("clearAssociatedTags");
		},
		showEditObjectModal({ commit }, posoId: string) {
			commit("setEditPosoId", posoId);
			commit("setShowObjectEditModal", true);
		},
		hideEditObjectModal({ commit }) {
			commit("setEditPosoId", null);
			commit("setShowObjectEditModal", false);
		},
	},
};

export default shopFloorModule;
