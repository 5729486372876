import _Vue from "vue";

export default {
	install(vue: typeof _Vue, options?: any) {
		vue.mixin({
			mounted() {
				if (process.env.NODE_ENV?.toLowerCase() == "production") {
					return;
				}
				// Vuetify elements somehow have no componentTag. We use the first class string instead
				// Could potentially be improved if we could find a better fallback
				const component = (this as any).$options?._componentTag ?? ((this as any).$el as HTMLElement)?.classList?.[0];
				if (component == undefined) {
					return;
				}
				try {
					(this as any).$el?.setAttribute("data-component", component);
				} catch (e) {
					// This happens if there is no root-Element (or a comment as a root-element)
					// It can therefor be skipped without worries
				}
			},
		});
	},
};
