
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import ShiftIcon from "./ShiftIcon.vue";
import { SiteDTO } from "@/Commons/interfaces/restdto";
import { Getter } from "vuex-class";

@Component({
	components: {
		ShiftIcon,
	},
})
export default class ShiftIconList extends Vue {
	@Getter readonly site!: SiteDTO;
}
