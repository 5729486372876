
import { dateToTimeString } from "@/Commons/utils/timeUtils";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class MonitorTemplate extends Vue {
	@Prop({ required: true, type: Boolean }) readonly show!: boolean;
	@Prop({ default: null, type: Date }) readonly lastUpdate!: Date;
	@Prop({ default: "primary", type: String }) readonly color!: string;

	lastShowUpdate = new Date();

	get displayTime(): string {
		var update = this.lastUpdate || this.lastShowUpdate;
		return dateToTimeString(update, this.$t("LocalDateTimeFull"));
	}

	@Watch("show", { immediate: true })
	onShowUpdate(): void {
		this.lastShowUpdate = new Date();
	}
}
