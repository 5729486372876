import { AccountDTO, AccountRoleDTO } from "@/Commons/interfaces/restdto";
import Axios from "axios";
import { Module } from "vuex";
import { RootState } from "..";
import API from "../API";

interface AccountState {
	account?: AccountDTO;
	fetched: boolean;
}

const accountsState: Module<AccountState, RootState> = {
	namespaced: true,
	state: {
		account: null,
		fetched: false,
	},
	getters: {
		loaded: (state): boolean => state.account != null,
		username: (state): string => state.account?.username || "",
		roles: (state): AccountRoleDTO[] => state.account?.roles || [],
		claims: (state): string[] => state.account?.claims || [],
		loggedIn: (state): boolean => state.account?.active == true,
		user: (state): AccountDTO | undefined => state.account,
		userDefaultWorkstation: (state): string => state.account?.defaultWorkstation || "",
		defaultPage: (state): string => state.account.defaultPage,
		useWindowsAuth: (state): boolean => state.account?.useWindowsAuth || false,
		userWorkstations: (state): string[] => state.account?.workstations || [],
		modules: (state): Modules[] => state.account?.modules || [],
		isAdmin: (state): boolean => isAdmin(state.account),
		fetched: (state): boolean => state.fetched,
		determineVisibility: (state) => {
			return (options: { claim?: string; module?: Modules }): boolean => {
				if (options.module != null) {
					// module based visibility takes priority over claim based ones
					return state.account.modules.includes(options.module);
				}
				if (isAdmin(state.account)) return true;
				return state.account.claims.map((c) => c.toLowerCase()).includes(options.claim.toLowerCase());
			};
		},
	},
	mutations: {
		setAccount: (state, account: AccountDTO) => {
			state.account = account;
		},
		setFetched: (state, fetched: boolean) => {
			state.fetched = fetched;
		},
	},
	actions: {
		async fetchAccount({ commit }): Promise<boolean> {
			return new Promise<boolean>(async (resolve, reject) => {
				await Axios.get(API.accountInformation)
					.then((result) => {
						commit("setAccount", result.data);
						resolve(true);
					})
					.catch((e) => {
						window.console.error("Could not find login data:", e);
						reject(false);
					})
					.finally(() => {
						commit("setFetched", true);
					});
			});
		},
	},
};

export default accountsState;

export enum Modules {
	EventManagement = "EventManagement",
	Connectors = "Connectors",
	ActivitiesAndShifts = "ActivitiesAndShifts",
	ELabels = "ELabels",
	Reports = "Reports",
	ShopFloor = "ShopFloor",
	LiveSchema = "LiveSchema",
	SystemMonitor = "SystemMonitor",
	DigitalPicker = "DigitalPicker",
	Debug = "Debug",
	VehicleParking = "VehicleParking",
}

export function isAdmin(account: AccountDTO) {
	return account?.roles?.some((r) => r.Name == "Admin") ?? false;
}

export enum Claims {
	ModifyObject = "ModifyObject",
}
