
import { Vue, Component } from "vue-property-decorator";
import CopyrightModal from "./Modals/CopyrightModal.vue";
import { VersionDTO } from "@/Commons/interfaces/restdto";
import Axios, { AxiosResponse } from "axios";
import API from "@/store/API";

@Component({
	components: {
		CopyrightModal,
	},
})
export default class InformationMenu extends Vue {
	showCopyrightModal = false;
	showReleaseNotesModal = false;
	version: VersionDTO = {
		version: "...",
		releaseVersion: "...",
	};

	get manualLink() {
		return "manuals/" + this.$i18n.locale + "/index.html";
	}

	get releaseNotesLink() {
		return "manuals/" + this.$i18n.locale + "/release-notes.html";
	}

	created() {
		Axios.get(API.model.version)
			.then((response: AxiosResponse<VersionDTO>) => {
				this.version = response.data;
			})
			.catch((error) => {
				window.console.error("Could not load Version Information:", error);
			});
	}

	copyReleaseVersion() {
		navigator.clipboard.writeText(this.version.releaseVersion);
	}
}
