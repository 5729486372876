
import { Vue, Component, Prop } from "vue-property-decorator";
import ItxModal from "@/Commons/Components/ItxModal.vue";
import { loadLanguageAsync } from "@/i18n";
import { namespace } from "vuex-class";
import axios from "axios";
import { AccountDTO } from "@/Commons/interfaces/restdto";
import API from "@/store/API";
import { GlobalNotification } from "@/store/modules/errorsModule";

const Account = namespace("Account");
const Errors = namespace("Errors");

@Component({
	components: {
		ItxModal,
	},
})
export default class UserMenu extends Vue {
	@Prop({ default: false, type: Boolean }) readonly disabled: boolean;

	languageModal = false;

	get initialIcon(): string {
		return this.roles?.[0]?.IconType ?? "DefaultUser";
	}

	changeLang(lang: string) {
		loadLanguageAsync(lang);
		this.$vuetify.lang.current = lang;
		this.languageModal = false;
	}

	@Account.Getter username: string;
	@Account.Getter roles: any[];
	@Account.Getter useWindowsAuth: boolean;
	@Account.Mutation setAccount: (account: AccountDTO) => void;

	@Errors.Mutation pushNotification: (payload: GlobalNotification) => void;

	logout(): void {
		axios
			.get(API.account.logout)
			.then(() => {
				this.setAccount(null);
				this.$router.push("/");
				window.location.hash = "#/";
			})
			.catch(() => {
				this.pushNotification({
					text: this.$t("LogoutFailed"),
					type: "error",
				});
			});
	}
}
