import { LiveMapConfigDTO } from "@/Commons/interfaces/restdto";
import { LayerInfo, SearchParametersDTO, svgDataDTO, VisibilityInfoDTO } from "@/MapApp/interfaces/dto";
import Axios from "axios";
import { Map } from "ol";
import { Module } from "vuex";
import { RootState } from "..";
import API from "../API";

interface MapState {
	svgCache?: svgDataDTO;
	siteDefaultViewLastFocus: number;
	map?: Map;
	searchOnMap: SearchParametersDTO | null;
	currentLayerConfig: VisibilityInfoDTO | null;
	config: LiveMapConfigDTO | null;
}
const mapModule: Module<MapState, RootState> = {
	namespaced: true,
	state: {
		svgCache: undefined,
		siteDefaultViewLastFocus: Date.now(),
		map: undefined,
		searchOnMap: null,
		currentLayerConfig: null,
		config: null,
	},
	getters: {
		svgCache: (state) => state.svgCache,
		siteDefaultViewLastFocus: (state) => state.siteDefaultViewLastFocus,
		currentView: (state) => (state.map ? state.map.getView() : undefined),
		searchOnMap: (state) => (state.searchOnMap ? state.searchOnMap : null),
		currentLayerConfig: (state) => state.currentLayerConfig,
		config: (state) => state.config,
	},
	mutations: {
		setSiteDefaultViewLastFocus: (state) => {
			state.siteDefaultViewLastFocus = Date.now();
		},
		setSvgCache: (state, data: svgDataDTO) => {
			state.svgCache = data;
		},
		setMap: (state, data: Map) => {
			state.map = data;
		},
		setSearchOnMap: (state, data: SearchParametersDTO) => {
			state.searchOnMap = data;
		},
		setLayerConfig: (state, layerconfig: VisibilityInfoDTO) => {
			state.currentLayerConfig = layerconfig;
		},
		setMapConfig: (state, mapConfig: LiveMapConfigDTO) => {
			state.config = mapConfig;
		},
	},
	actions: {
		setSearchOnMap: ({ commit }, searchParam: SearchParametersDTO) => {
			commit("setSearchOnMap", searchParam);
		},
		fetchMapConfig: ({ commit }) => {
			Axios.get<LiveMapConfigDTO>(API.map.config)
				.then((response) => {
					commit("setMapConfig", response.data);
				})
				.catch((error) => {
					window.console.error("Could not load Map configuration", error);
				});
		},
	},
};

export default mapModule;
