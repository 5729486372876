
import { GlobalNotification } from "@/store/modules/errorsModule";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Errors = namespace("Errors");

@Component({})
export default class NotificationIcon extends Vue {
	@Errors.Getter notifications!: GlobalNotification[];
}
