import { Module } from "vuex";
import { RootState } from "../index";
import { TranslateResult } from "vue-i18n";

export type NotificationType = "success" | "error" | "info" | "warn";
export interface GlobalNotification {
	text: TranslateResult;
	type: NotificationType;
	namespace?: string;
	hide?: boolean;
	autohide?: boolean;
	disableLog?: boolean;
	payload?: any;
}

export type GlobalNotificationInternal = GlobalNotification & {
	id: number;
	created: Date;
};

export type ErrorsState = {
	currentId: number;
	notifications: GlobalNotificationInternal[];
};

const getConsoleLogger = (level: NotificationType) => {
	switch (level) {
		case "error":
			return window.console.error;
		case "success":
			return window.console.log;
		case "info":
			return window.console.info;
		case "warn":
			return window.console.warn;
	}
};

export const errorsModule: Module<ErrorsState, RootState> = {
	namespaced: true,
	state: {
		currentId: 0,
		notifications: [],
	},
	getters: {
		notifications: (state) => state.notifications,
		activeNotifications: (state) => state.notifications.filter((n) => n.hide != true),
	},
	mutations: {
		pushNotification: (state, payload: GlobalNotification) => {
			const notification: GlobalNotificationInternal = Object.assign(
				{ hide: false, created: new Date(), autohide: true, disableLog: false },
				payload,
				{
					id: state.currentId,
				},
			);
			state.notifications.push(notification);
			state.currentId++;

			if (notification.disableLog) return;

			const log = getConsoleLogger(notification.type);
			if (notification.payload) log(notification.text, notification.payload);
			else log(notification.text);
		},
		hideNotification: (state, payload: number) => {
			const notification = state.notifications.find((n) => n.id == payload);
			if (notification != null) {
				notification.hide = true;
			}
		},
		hideNotifications: (state, payload: number[]) => {
			state.notifications
				.filter((n) => payload.includes(n.id))
				.forEach((n) => {
					n.hide = true;
				});
		},
		deleteNotification: (state, payload: number) => {
			state.notifications = state.notifications.filter((n) => n.id != payload);
		},
		clearNotifications: (state) => {
			state.notifications = [];
		},
	},
};
