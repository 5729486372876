export function isModeDevelop() {
	return (
		window.hasOwnProperty("webpackHotUpdate") ||
		(process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test" && typeof console !== "undefined")
	);
}

export function isIFrame() {
	return window.self !== window.top;
}
