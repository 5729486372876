
import { AccountDTO } from "@/Commons/interfaces/restdto";
import axios from "axios";
import { TranslateResult } from "vue-i18n";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import API from "@/store/API";
import { getDefaultRoute } from "@/router";

interface LoginInfoDTO {
	internal: {
		enabled: boolean;
	};
	external: {
		enabled: boolean;
		displayName: string;
		scheme: string;
	};
}

const Account = namespace("Account");

@Component({ components: {} })
export default class LoginPage extends Vue {
	@Account.Mutation setAccount!: (account: AccountDTO) => void;

	valid = false;
	password = "";
	username = "";
	wrongPassword = false;
	rateLimited = false;
	rateLimitedDuration = 0;
	returnToUri = true;

	loginConfig: LoginInfoDTO = null;

	get logoUrl() {
		return API.theme.logoLogin;
	}

	get externalLoginURL(): string {
		return `${API.account.loginExternal}?scheme=${this.loginConfig?.external?.scheme}&returnurl=/#${window.location.hash}`;
	}

	get externalButtonText(): TranslateResult {
		return this.loginConfig?.external?.displayName ?? this.$t("ExternalLogin");
	}

	async created(): Promise<void> {
		this.loginConfig = (await axios.get<LoginInfoDTO>(API.model.login))?.data;
		if (this.loginConfig?.internal.enabled == false) {
			window.location.replace(this.externalLoginURL);
		}
	}

	get returnUri(): string {
		let params = new URLSearchParams(window.location.search);
		return params.get("returnUrl");
	}

	login(): void {
		if (this.rateLimited) {
			return;
		}
		if (this.username == "" || this.password == "") {
			return;
		}
		this.wrongPassword = false;
		axios
			.post<AccountDTO>(API.account.login, {
				username: this.username,
				password: this.password,
			})
			.then((result) => {
				this.setAccount(result.data);
				window.console.error("search:", window.location.search);
				if (this.returnUri) {
					if (this.returnToUri) {
						window.location.assign(this.returnUri);
						return;
					}
					window.location.assign(window.location.href.split("?")[0]);
					return;
				}
				if (this.$router.currentRoute.path == "/") {
					window.location.reload();
				} else if (window.location.hash == "#/") {
					this.$nextTick(() => {
						let route = getDefaultRoute(result.data.defaultPage);
						this.$router.push({ name: route.name });
					});
				} else {
					this.setAccount(result.data);
				}
			})
			.catch((error) => {
				window.console.error({ error }, error?.response?.status);
				switch (error?.response?.status) {
					case 400:
						this.wrongPassword = true;
						break;
					case 429:
						let delay = error?.response?.headers?.["retry-after"] ?? 5;
						this.rateLimitedDuration = delay * 1000;
						this.rateLimited = true;
				}
			});
	}
}
