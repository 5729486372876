
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mutation, namespace } from "vuex-class";
import Axios from "axios";

import { SiteDTO } from "@/Commons/interfaces/restdto";
import API from "@/store/API";

const Map = namespace("Map");

@Component({})
export default class SiteSelector extends Vue {
	sites: SiteDTO[] = [];
	currentSite: SiteDTO = null;
	@Map.Getter siteDefaultViewLastFocus;
	@Map.Mutation setSiteDefaultViewLastFocus;
	@Mutation setSite;
	@Mutation setSites;

	mounted() {
		Axios.get(API.model.sites, { params: { include: ["productionarea", "fixedzones", "fixedzones.activity"] } }).then((value) => {
			this.sites = value.data;
			this.currentSite = this.sites[0];
		});
	}

	onSiteLocationClick() {
		this.setSiteDefaultViewLastFocus();
	}

	@Watch("currentSite")
	updatedCurrentSite(val: SiteDTO) {
		this.setSite(val);
	}

	onChange() {
		(this.$refs.selector as any).blur?.();
	}

	@Watch("sites")
	updatedSites(val: SiteDTO[]) {
		this.setSites(val);
	}
}
