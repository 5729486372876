import API from "@/store/API";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import CommunicationInterface, { Channels, Global } from "../interfaces/FrontendCommunication";

interface Cache {
	func: (channel: string, callbacks: Channels) => boolean;
	channel: string;
	callbacks: Channels;
}

export default class FrontendCommunication implements CommunicationInterface {
	private socket: HubConnection;
	private _started = false;
	private _error = false;
	private possibleChannels: string[] = [];
	private channels: { [key: string]: Channels[] } = {};
	private cache: Cache[] = [];
	private _reconnectAttempts = [0, 2000, 10000, 30000, 30000, 30000, 60000, 60000, 60000];
	private frontendKeepAliveId = -1;

	constructor() {
		window.console.info("FrontendCommunication: Starting");
		this.socket = new HubConnectionBuilder()
			.withUrl(API.comHub)
			.withAutomaticReconnect(this._reconnectAttempts)
			.configureLogging(LogLevel.Debug)
			.build();

		this.socket.on("Message", this.handleMessages);
		this.socket.on("Error", this.handleError);

		this.socket.onreconnecting(() => {
			this._started = false;
			this._error = true;
		});

		this.socket.onreconnected(() => {
			window.console.debug("FrontendCommunication: Connection restarted");
			Object.keys(this.channels).forEach((channel) => {
				this.socket.invoke("Subscribe", channel);
			});
			this._started = true;
			this._error = false;
			this.applyCache();
		});
		this.socket.onclose(() => {
			window.console.debug("FrontendCommunication: Connection closed");
			this._started = false;
			this._error = true;
		});

		this.channels.Global = [
			{
				channels: (channels: string[]) => {
					window.console.debug("FrontendCommunication: Setting possible channels to ", channels);
					this.possibleChannels = channels;
				},
			} as Global,
		];

		this.socket.start().then(() => {
			this._started = true;
			this._error = false;
			this.applyCache();

			this.frontendKeepAliveId = window.setInterval(() => this.socket.invoke("KeepAlive"), 100 * 60 * 30);
		});
	}

	public get connectionId(): string | null {
		return this.socket?.connectionId;
	}

	public get started(): boolean {
		return this._started;
	}

	public get error(): boolean {
		return this._error;
	}

	public get running(): boolean {
		return this._started && !this._error;
	}

	public get reconnectAttempts(): number[] {
		return this._reconnectAttempts;
	}

	isSubscribed = (channel: string, callbacks: Channels): boolean => {
		return this._started && this.channels.hasOwnProperty(channel) && this.channels[channel].includes(callbacks);
	};

	subscribe = (channel: string, callbacks: Channels): boolean => {
		if (!this._started) {
			window.console.debug("adding to cache", channel);
			this.cache.push({ func: this.subscribe, channel: channel, callbacks: callbacks });
			return false;
		}

		if (!this.channels.hasOwnProperty(channel) || this.channels[channel].length == 0) {
			window.console.debug("FrontendCommunication: First subscription for ", channel, "created. Subbing");
			this.socket.invoke("Subscribe", channel);
			this.channels[channel] = [];
		}

		if (!this._started) {
			this.cache.push({ func: this.subscribe, channel: channel, callbacks: callbacks });
			return false;
		}

		this.channels[channel].push(callbacks);

		return true;
	};

	unsubscribe = (channel: string, callbacks: Channels): boolean => {
		if (!this.possibleChannels.includes(channel)) {
			return false;
		}

		if (!this.isSubscribed(channel, callbacks)) {
			return false;
		}

		this.channels[channel].splice(this.channels[channel].indexOf(callbacks), 1);

		if (this.channels[channel].length == 0) {
			window.console.debug("FrontendCommunication: Last Subscription for ", channel, "removed. Unsubbing");
			this.socket.invoke("Unsubscribe", channel);
		}

		return true;
	};

	private handleMessages = (channel: string, action: string, ...messages: any): void => {
		window.console.debug("FrontendCommunication: Received Message for channel", channel, "(", action, "):", ...messages);
		if (this.channels.hasOwnProperty(channel)) {
			this.channels[channel].forEach((channel: Channels) => {
				if (channel.hasOwnProperty(action)) {
					try {
						channel[action](...messages);
					} catch (e) {
						window.console.error("FrontendCommunication: Error during callback", channel, action, ...messages, e);
					}
				}
			});
		}
	};

	private handleError = (error: any): void => {
		window.console.error(error);
	};

	private applyCache() {
		this.cache.forEach((cache) => cache.func(cache.channel, cache.callbacks));
		this.cache = [];
	}
}
